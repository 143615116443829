<template>
  <div id="app">
    <vue-extend-layout2 />
  </div>
</template>

<script>
  import VueExtendLayout2 from 'vue-extend-layout/example/vue-extend-layout'
  export default {
    components: { VueExtendLayout2 }
  }
</script>

<style lang="scss">

</style>